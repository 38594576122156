import $ from '../../shared/dom.js';

export default function updateClickedSlide(e) {
  const swiper = this;
  const params = params;
  const slide = $(e).closest(`.${params.slideClass}`)[0];
  let slideFound = false;
  let slideIndex;

  if (slide) {
    for (let i = 0; i < slides.length; i += 1) {
      if (slides[i] === slide) {
        slideFound = true;
        slideIndex = i;
        break;
      }
    }
  }

  if (slide && slideFound) {
    clickedSlide = slide;
    if (virtual && params.virtual.enabled) {
      clickedIndex = parseInt($(slide).attr('data-swiper-slide-index'), 10);
    } else {
      clickedIndex = slideIndex;
    }
  } else {
    clickedSlide = undefined;
    clickedIndex = undefined;
    return;
  }
  if (
    params.slideToClickedSlide &&
    clickedIndex !== undefined &&
    clickedIndex !== activeIndex
  ) {
    slideToClickedSlide();
  }
}
