export default function setGrabCursor(moving) {
  const swiper = this;
  if (
    support.touch ||
    !params.simulateTouch ||
    (params.watchOverflow && isLocked) ||
    params.cssMode
  )
    return;
  const el = params.touchEventsTarget === 'container' ? el : wrapperEl;
  el.style.cursor = 'move';
  el.style.cursor = moving ? 'grabbing' : 'grab';
}
