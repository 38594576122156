export default function onClick(e) {
  const swiper = this;
  if (!enabled) return;
  if (!allowClick) {
    if (params.preventClicks) e.preventDefault();
    if (params.preventClicksPropagation && animating) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }
}
